import { CHANGE_LANGUAGE, CHANGE_LANGUAGUE, CHANGE_NOTIFICATIONS, CHANGE_TOKEN ,CHANGE_USER_DATA } from './GlobalActionsTypes' 


export const changeLanguageAction = (code)=>{
    return{
        type:CHANGE_LANGUAGE,
        lang:code
    }
}
export const changeToken = (token)=>{
    return{
        type:CHANGE_TOKEN,
        token:token
    }
}
export const changeUserData = (userData)=>{
    return{
        type:CHANGE_USER_DATA,
        userData:userData
    }
}
export const chengeLang = (code)=>{
    return{
        type:CHANGE_LANGUAGUE,
        lang:code
    }
}
export const chengeNotifications = (notifications)=>{
    return{
        type:CHANGE_NOTIFICATIONS,
        notifications:notifications
    }
}